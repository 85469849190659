<template>
  <span
    class="ml-2 inline-flex px-3 py-0.5 rounded-full text-xs leading-5 font-semibold tracking-wide uppercase"
    :class="bgClass"
  >
    {{ tier }}
  </span>
</template>
<script>
export default {
  name: 'TeamTierBadge',

  props: ['tier'],

  computed: {
    bgClass () {
      switch (this.tier) {
        case 'enterprise':
          return 'bg-indigo-100 text-indigo-600'
        case 'pro':
          return 'bg-blue-100 text-blue-600 dark:bg-blue-800 dark:text-blue-200'
        case 'studio':
          return 'bg-teal-100 text-teal-600 dark:bg-teal-800 dark:text-teal-200'
        case 'education':
          return 'bg-indigo-100 text-indigo-600 dark:bg-indigo-800 dark:text-indigo-200'
        case 'founder':
          return 'bg-purple-800 text-purple-200 dark:bg-purple-800 dark:text-purple-200'
        case 'free':
        default:
          return 'bg-gray-100 text-gray-800'
      }
    }
  }
}
</script>
